const { listToDict } = require('../helpers');

const fieldTypes = listToDict(['signature', 'email', 'date', 'role', 'name', 'string']);
const requiredValueFieldTypes = [fieldTypes.EMAIL, fieldTypes.ROLE, fieldTypes.NAME];
const singleUseFieldTypes = [
    fieldTypes.SIGNATURE,
    fieldTypes.EMAIL,
    fieldTypes.ROLE,
    fieldTypes.NAME,
];

const fieldTypesValues = {
    [fieldTypes.SIGNATURE]: {
        type: fieldTypes.SIGNATURE,
        label: 'Signature',
        value: null,
    },
    [fieldTypes.EMAIL]: {
        type: fieldTypes.EMAIL,
        label: 'Email',
        value: null,
    },
    [fieldTypes.DATE]: {
        type: fieldTypes.DATE,
        label: 'Date',
        value: null,
    },
    [fieldTypes.ROLE]: {
        type: fieldTypes.ROLE,
        label: 'Role',
        value: null,
    },
    [fieldTypes.NAME]: {
        type: fieldTypes.NAME,
        label: 'Name',
        value: null,
    },
    [fieldTypes.STRING]: {
        type: fieldTypes.STRING,
        label: 'Custom',
        value: null,
    },
};

module.exports = {
    fieldTypes,
    fieldTypesValues,
    requiredValueFieldTypes,
    singleUseFieldTypes,
};
