import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import {
    defaultSectionConfigsMap,
    sectionTypes,
    sectionTypeNames,
} from '../../../../../../shared_config/sections';
import { DragIcon } from '../../..';

export const DrawerContentSection = ({
    actionsComponent,
    allowDragAndDrop,
    disableDragAndDrop,
    expanded,
    isChild,
    onClick,
    onExpandSectionClick,
    section,
    showShortName = false,
    useSectionDividers,
}) => {
    const styles = require('./index.scss');

    const isDivider = section.section_type === sectionTypeNames.DIVIDER;
    const isFirstDivider = useSectionDividers && section.index === 0 && isDivider;
    const dragDisabled = !allowDragAndDrop || isFirstDivider || disableDragAndDrop;

    return (
        <div
            className={classnames(styles.section, {
                [styles.childSection]: isChild,
            })}
            {...(onClick ? { onClick } : {})}
        >
            <div className={styles.title}>
                {(allowDragAndDrop || isDivider) && (
                    <div
                        className={classnames(styles.leftSideActionsContainer, {
                            [styles.leftSideActionsContainerWithDivider]: isDivider,
                        })}
                    >
                        {allowDragAndDrop && <DragIcon disabled={dragDisabled} />}

                        {isDivider && (
                            <div
                                className={classnames(styles.expandDivider, {
                                    [styles.expandDividerUndragable]: !allowDragAndDrop,
                                })}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onExpandSectionClick(!expanded);
                                }}
                            >
                                <i className={`fa fa-chevron-${expanded ? 'down' : 'right'}`} />
                            </div>
                        )}
                    </div>
                )}
                <div>
                    <h4
                        className={classnames({
                            [styles.childTitle]: isChild,
                            [styles.unboldTitle]: isChild || section.manualNumber,
                        })}
                    >
                        {(section.numbering || section.manualNumber) &&
                            !section.disableNumbering && (
                                <span
                                    className={classnames(styles.numbering, {
                                        [styles.manualNumber]: !!section.manualNumber,
                                    })}
                                >
                                    {section.numbering || section.manualNumber}&nbsp;
                                </span>
                            )}
                        {section.title}
                    </h4>
                    {!isDivider && (
                        <div
                            className={classnames(styles.sectionType, {
                                [styles.childTitle]: isChild,
                            })}
                        >
                            {showShortName && !!section.shortName && (
                                <Box component="span" mr={3}>
                                    {section.shortName}
                                </Box>
                            )}
                            Type: {defaultSectionConfigsMap[section.section_type].shortName}
                        </div>
                    )}
                </div>
            </div>
            {actionsComponent && (
                <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
                    {actionsComponent}
                </div>
            )}
        </div>
    );
};

DrawerContentSection.propTypes = {
    actionsComponent: PropTypes.node,
    allowDragAndDrop: PropTypes.bool,
    disableDragAndDrop: PropTypes.bool,
    expanded: PropTypes.bool.isRequired,
    isChild: PropTypes.bool,
    onClick: PropTypes.func,
    onExpandSectionClick: PropTypes.func.isRequired,
    section: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                index: PropTypes.number,
                title: PropTypes.string,
                section_type: PropTypes.oneOf(sectionTypes),
            })
        ),
        disableNumbering: PropTypes.bool,
        id: PropTypes.number,
        index: PropTypes.number,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        title: PropTypes.string,
        section_type: PropTypes.oneOf(sectionTypes),
        shortName: PropTypes.string,
    }).isRequired,
    showShortName: PropTypes.bool,
    useSectionDividers: PropTypes.bool,
};
