import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { arrayMove, Field } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { getDndStyle } from '../../../constants/styles';
import { DragIcon } from '../../DragIcon';
import {
    fieldTypes,
    fieldTypesValues,
    singleUseFieldTypes,
} from '../../../../../shared_config/signatures';
import { InputText } from '../../InputText';
import { DateTimePicker } from '../../DateTime/DateTimePicker';
import { CDSButton, CDSDropdownButton } from '../../SDv2';
import { MenuItem } from '../../MenuItem/MenuItem';
import { blockFieldNames, fieldsEditableFieldNames } from './constants';

const { VALUE, LABEL } = fieldsEditableFieldNames;
const { FIELDS } = blockFieldNames;

const RenderFieldValue = ({ field, fieldValue, disabled, showValidation }) => {
    switch (fieldValue.type) {
        case fieldTypes.DATE: {
            return (
                <Field
                    component={DateTimePicker}
                    disabled={disabled}
                    hasFeedback={false}
                    horizontal
                    name={`${field}.${VALUE}`}
                    placeholder="M/D/YYYY"
                    showValidation={showValidation}
                    time={false}
                    timezone={undefined}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.EMAIL:
        case fieldTypes.ROLE:
        case fieldTypes.NAME:
        case fieldTypes.STRING: {
            return (
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={null}
                    name={`${field}.${VALUE}`}
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.SIGNATURE:
        default: {
            return <Box height="30px">&nbsp;</Box>;
        }
    }
};
RenderFieldValue.propTypes = {
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    fieldValue: PropTypes.object.isRequired,
    showValidation: PropTypes.bool,
};

export const SignatureField = ({ disabled, fields, form, showValidation }) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const availableOptions = useMemo(() => {
        const fieldsData = fields.getAll();
        const usedTypes = fieldsData.map((field) => field.type);

        return Object.values(fieldTypesValues).filter((fieldType) => {
            return (
                !usedTypes.includes(fieldType.type) || !singleUseFieldTypes.includes(fieldType.type)
            );
        });
    }, [fields.length]);

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    return (
        <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="signatureBlockFieldsList"
                    isDropDisabled={disabled}
                    type={FIELDS}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            {fields.map((field, index) => (
                                <Draggable draggableId={field} index={index} key={field}>
                                    {(draggableProvided) => (
                                        <Box
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                        >
                                            <Box className={styles.fieldSet}>
                                                <Box alignItems="center" display="flex">
                                                    <Box pr={2}>
                                                        <DragIcon
                                                            dragHandleProps={
                                                                draggableProvided.dragHandleProps
                                                            }
                                                        />
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Box className={styles.borderlessInput}>
                                                            <Field
                                                                component={InputText}
                                                                disabled={disabled}
                                                                hasFeedback={false}
                                                                label={null}
                                                                name={`${field}.${LABEL}`}
                                                                showValidation={showValidation}
                                                                useOpenGovStyle
                                                            />
                                                        </Box>
                                                        <RenderFieldValue
                                                            field={field}
                                                            fieldValue={fields.get(index)}
                                                            {...{ disabled, showValidation }}
                                                        />
                                                    </Box>
                                                    <Box ml={2} textAlign="center">
                                                        <CDSButton
                                                            className={styles.error}
                                                            onClick={() => fields.remove(index)}
                                                            qaTag="signatureForm-removeField"
                                                            size="small"
                                                            variant="text"
                                                        >
                                                            <i className="fa fa-times" />
                                                        </CDSButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Box>
                <CDSDropdownButton
                    disabled={disabled}
                    qaTag="signatureForm-addField"
                    size="small"
                    title={
                        <>
                            <i className="fa fa-plus" /> Add Signature Field
                        </>
                    }
                    variant="text"
                >
                    {availableOptions.map((fieldType) => (
                        <MenuItem
                            key={fieldType.type}
                            onClick={() => fields.push(fieldType)}
                            qaTag={`signatureForm-addField${fieldType.type}`}
                        >
                            {fieldType.label}
                        </MenuItem>
                    ))}
                </CDSDropdownButton>
            </Box>
        </Box>
    );
};

SignatureField.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    showValidation: PropTypes.bool,
};
