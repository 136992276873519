import { listToDict } from '../../../../../shared_config/helpers';
import { fieldTypes, fieldTypesValues } from '../../../../../shared_config/signatures';

export const fieldNames = listToDict(['footer', 'introduction', 'blocks', 'blankBlocks']);
export const blockFieldNames = listToDict(['header', 'pageBreakAfter', 'fields']);
export const fieldsEditableFieldNames = listToDict(['label', 'value']);

export const defaultBlock = {
    header: '',
    pageBreakAfter: false,
    fields: [fieldTypesValues[fieldTypes.SIGNATURE], fieldTypesValues[fieldTypes.NAME]],
};
