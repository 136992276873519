import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { arrayMove, Field, FieldArray } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { getDndStyle } from '../../../constants/styles';
import { Checkbox } from '../../Checkbox/Checkbox';
import { DragIcon } from '../../DragIcon';
import { RichTextInput } from '../../RichTextInput';
import { CDSButton } from '../../SDv2';
import { ContentBlock } from '../../SDv2/ContentBlock';
import { Button } from '../../Button';
import { blockFieldNames, defaultBlock, fieldNames } from './constants';
import { SignatureField } from './Field';

const { BLOCKS } = fieldNames;
const { FIELDS, HEADER } = blockFieldNames;

const FieldsMap = ({ fields, form, disabled, showValidation, move }) => {
    const styles = require('./index.scss');

    return (
        <>
            {fields.map((field, index) => (
                <Draggable draggableId={field} index={index} key={field}>
                    {(draggableProvided) => (
                        <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            <ContentBlock withActions>
                                <ContentBlock.Main disabled={disabled}>
                                    <Box
                                        borderRadius={1}
                                        className={styles.border}
                                        mb={2}
                                        pt={2}
                                        px={2}
                                    >
                                        <Box
                                            className={classnames(
                                                styles.label,
                                                styles.borderBottom
                                            )}
                                        >
                                            Header (Optional)
                                        </Box>
                                        <Box pt={2}>
                                            <Field
                                                borderless
                                                component={RichTextInput}
                                                disabled={disabled}
                                                label={null}
                                                minRows={2}
                                                name={`${field}.${HEADER}`}
                                                placeholder="Enter Text"
                                                qaTag="signatureForm-header"
                                                showValidation={showValidation}
                                                useOpenGovStyle
                                                useSharedTextareaToolbar
                                            />
                                        </Box>
                                    </Box>

                                    <FieldArray
                                        component={SignatureField}
                                        disabled={disabled}
                                        form={form}
                                        name={`${field}.${FIELDS}`}
                                        showValidation={showValidation}
                                    />
                                    <Box mt={3}>
                                        <Field
                                            component={Checkbox}
                                            disabled={disabled}
                                            inline
                                            name={`${field}.PAGE_BREAK_AFTER`}
                                            qaTag="signatureForm-pageBreak"
                                            text="Page break after this Block"
                                            useOpenGovStyle
                                        />
                                    </Box>
                                </ContentBlock.Main>
                                <ContentBlock.ActionSidebar>
                                    <ContentBlock.ButtonGroup>
                                        {index > 0 && (
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => move(index, index - 1)}
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="fa fa-arrow-up"
                                                    />
                                                </Button>
                                            </ContentBlock.Button>
                                        )}
                                        <ContentBlock.Button>
                                            <DragIcon
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                            />
                                        </ContentBlock.Button>
                                        {index < fields.length - 1 && (
                                            <ContentBlock.Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => move(index, index + 1)}
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="fa fa-arrow-down"
                                                    />
                                                </Button>
                                            </ContentBlock.Button>
                                        )}
                                    </ContentBlock.ButtonGroup>
                                    <ContentBlock.ButtonGroup>
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => fields.remove(index)}
                                            >
                                                <i aria-hidden="true" className="fa fa-trash" />
                                            </Button>
                                        </ContentBlock.Button>
                                    </ContentBlock.ButtonGroup>
                                </ContentBlock.ActionSidebar>
                            </ContentBlock>
                        </Box>
                    )}
                </Draggable>
            ))}
        </>
    );
};
FieldsMap.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    move: PropTypes.func.isRequired,
    showValidation: PropTypes.bool,
};

export const SignatureBlock = ({ disabled, fields, form, showValidation }) => {
    const dispatch = useDispatch();

    const move = (originLocation, newLocation) => {
        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        move(originLocation, newLocation);
    };

    return (
        <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="signatureBlockList" isDropDisabled={disabled} type={BLOCKS}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldsMap
                                fields={fields}
                                move={move}
                                {...{ disabled, showValidation, form }}
                            />

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Box pb={2}>
                <CDSButton
                    color="secondary"
                    onClick={() => fields.push(defaultBlock)}
                    qaTag="signatureForm-addBlock"
                    size="small"
                    variant="secondary"
                >
                    <i className="fa fa-plus" /> Add Signature Block
                </CDSButton>
            </Box>
        </Box>
    );
};

SignatureBlock.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    // meta: PropTypes.object.isRequired,
    showValidation: PropTypes.bool,
};
