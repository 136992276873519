import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import classnames from 'classnames';
import { Box, Grid } from '@og-pro/ui';

import { SignatureBlock } from './Block';
import { RichTextInput } from '../../RichTextInput';
import { fieldNames } from './constants';

const { FOOTER, INTRODUCTION } = fieldNames;

export const SignatureForm = ({
    disabled,
    form,
    formKey,
    showValidation,
    tagOptions,
    templateVariableOptions,
}) => {
    const styles = require('./index.scss');

    return (
        <Box className={styles.container}>
            <Box className={styles.contentBox} mb={3} px={4}>
                <Box className={classnames(styles.label, styles.borderBottom)}>
                    Introductory Text (Optional)
                </Box>
                <Box pt={2}>
                    <Field
                        borderless
                        component={RichTextInput}
                        disabled={disabled}
                        label={null}
                        minRows={3}
                        name={`${formKey}.${INTRODUCTION}`}
                        placeholder="Enter Text"
                        showValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <Box className={styles.blockColumn}>
                        <FieldArray
                            component={SignatureBlock}
                            disabled={disabled}
                            form={form}
                            name={`${formKey}.blocks[0]`}
                            showValidation={showValidation}
                        />
                    </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box className={styles.blockColumn}>
                        <FieldArray
                            component={SignatureBlock}
                            disabled={disabled}
                            form={form}
                            name={`${formKey}.blocks[1]`}
                            showValidation={showValidation}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box className={styles.contentBox} mt={3} px={4}>
                <Box className={classnames(styles.label, styles.borderBottom)}>
                    Concluding Text (Optional)
                </Box>
                <Box pt={2}>
                    <Field
                        borderless
                        component={RichTextInput}
                        disabled={disabled}
                        label={null}
                        minRows={3}
                        name={`${formKey}.${FOOTER}`}
                        placeholder="Enter Text"
                        showValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useOpenGovStyle
                        useSharedTextareaToolbar
                    />
                </Box>
            </Box>
        </Box>
    );
};

SignatureForm.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    showValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};
