import { listToDict } from '../../../../../../../shared_config/helpers';

const cols = [
    'requestNumber',
    'description',
    'totalAmount',
    'lastAction',
    'currentStep',
    'next',
    'creator',
    'approvalGroup',
];

export const colsDict = listToDict(cols);

const {
    REQUEST_NUMBER,
    DESCRIPTION,
    TOTAL_AMOUNT,
    LAST_ACTION,
    CURRENT_STEP,
    NEXT,
    CREATOR,
    APPROVAL_GROUP,
} = colsDict;

export const columns = {
    [REQUEST_NUMBER]: {
        label: 'Request Number',
        width: 170,
        headAlign: 'center',
        contentAlign: 'left',
    },
    [DESCRIPTION]: {
        label: 'Description',
        width: 325,
        headAlign: 'left',
        contentAlign: 'left',
    },
    [TOTAL_AMOUNT]: {
        label: 'Total Amount',
        width: 149,
        headAlign: 'right',
        contentAlign: 'right',
    },
    [LAST_ACTION]: {
        label: 'Last Action',
        width: 261,
        headAlign: 'left',
        contentAlign: 'left',
    },
    [CURRENT_STEP]: {
        label: 'Current Step',
        width: 230,
        headAlign: 'left',
        contentAlign: 'left',
    },
    [NEXT]: {
        label: 'Next',
        width: 200,
        headAlign: 'left',
        contentAlign: 'left',
    },
    [CREATOR]: {
        label: 'Creator',
        width: 200,
        headAlign: 'left',
        contentAlign: 'left',
    },
    [APPROVAL_GROUP]: {
        label: 'Approval Group',
        width: 222,
        headAlign: 'left',
        contentAlign: 'left',
    },
};
