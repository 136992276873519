import { debounce, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    Close as CloseIcon,
    Output as OutputIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import { Box, Button, InputAdornment, Switch, TextField, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { CertificationsMenu } from './CertificationsMenu';
import { CACHE_BLOCK_SIZE, DEFAULT_PAGE_SIZE } from './constants';
import { retrieveVendors } from './helpers';
import { AgGridReact } from '..';
import { CategorySelectButton } from '../../containers/CategorySelect';
import { FiltersMenu } from './FiltersMenu';
import {
    loadProcuratedRatingsClient,
    loadPublicVendorLists,
    loadVendorLists,
} from '../../actions/vendorList';
import Columns from './Columns';
import { TakeActionsMenu } from './TakeActionsMenu';
import { getOpenSearchObject } from '../../helpers/vendorSearch';
import {
    ArrayCellRenderer,
    ExpandableArrayCellRenderer,
    VendorNameCellRenderer,
    WebsiteCellRenderer,
} from '../AgGridReact/renderers';
import { TextCellRenderer } from './cellRenderers/TextCellRenderer';
import { downloadVendorSearch } from '../../actions/vendorSearch';
import { showVendorProfileModal } from '../../actions/vendorProfile';
import { CategoriesCellRenderer } from './cellRenderers/CategoriesCellRenderer';

const DEFAULT_SEARCH_PARAMS = {
    categories: '',
    certifications: '',
    ethnicities: [],
    languages: [],
    searchText: '',
    vendorLists: [],
};

export const VendorSearchTable = ({
    addVendorsToList,
    addVendorsToListBySearch,
    blockVendors,
    emailVendors,
    emailVendorsBySearch,
    governmentId,
    inviteVendorsToProject,
    inviteVendorsToProjectBySearch,
    searchVendors,
    isPublic,
}) => {
    const dispatch = useDispatch();

    const styles = require('./index.scss');

    const [subscriberView, setSubscriberView] = useState(false);

    const [gridApi, setGridApi] = useState(null);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams(DEFAULT_SEARCH_PARAMS);

    const categories = searchParams.get('categories');
    const categoryIds = categories ? categories.split(',') : [];

    const certifications = searchParams.get('certifications');
    const certificationIds = certifications
        ? certifications.split(',').map((certification) => parseInt(certification, 10))
        : [];

    const ethnicities = searchParams.get('ethnicities')
        ? searchParams.get('ethnicities').split(',')
        : [];

    const vendorLists = searchParams.get('vendorLists')
        ? searchParams.get('vendorLists').split(',')
        : [];

    const languages = searchParams.get('languages') ? searchParams.get('languages').split(',') : [];

    const searchText = searchParams.get('searchText');

    const searchObject = {
        searchText,
        categoryIds,
        certificationIds,
        ethnicities,
        vendorLists,
        languages,
    };

    const hasFilter =
        categoryIds.length > 0 ||
        certificationIds.length > 0 ||
        ethnicities.length > 0 ||
        vendorLists.length > 0 ||
        languages.length > 0 ||
        !!searchText;

    const defaultColDef = {
        editable: false,
        sortable: false,
        suppressMenu: true,
    };

    const handleSelectionChanged = () => {
        const newSelectedUserIds = gridApi.getSelectedRows().flatMap((row) => {
            return row.subscriberId;
        });
        setSelectedUserIds(newSelectedUserIds);
    };

    const loadVendors = (api) => {
        const searchApi = retrieveVendors(
            searchVendors,
            governmentId,
            searchObject,
            loadProcuratedRatingsClient,
            dispatch,
            isPublic,
            subscriberView
        );

        api?.setServerSideDatasource(searchApi);
    };

    useEffect(() => {
        if (isPublic) {
            dispatch(loadPublicVendorLists(governmentId));
        } else {
            dispatch(loadVendorLists(governmentId));
        }
    }, []);

    useEffect(() => {
        const debounceLoadVendors = debounce(() => {
            loadVendors(gridApi);
        }, 300);

        debounceLoadVendors();

        return () => {
            debounceLoadVendors.cancel();
        };
    }, [searchParams, subscriberView]);

    const getRowNodeId = (data) => data.id;

    const onGridReady = (params) => {
        loadVendors(params.api);
        setGridApi(params.api);
    };

    const onCategorySelect = (selectedCategories, hasChanged) => {
        if (hasChanged) {
            searchParams.set(
                'categories',
                selectedCategories.map((selectedCategory) => selectedCategory.id).join(',')
            );

            setSearchParams(searchParams);
        }
    };

    const onCertificationSelect = (selectedCertification) => {
        const currentIndex = certificationIds.indexOf(selectedCertification);
        const newSelected = [...certificationIds];
        if (currentIndex === -1) {
            newSelected.push(selectedCertification);
        } else {
            newSelected.splice(currentIndex, 1);
        }
        searchParams.set('certifications', newSelected.join(','));
        setSearchParams(searchParams);
    };

    const onExportClick = () => {
        dispatch(
            downloadVendorSearch(
                governmentId,
                {
                    searchObject,
                    sortModel: [],
                },
                isPublic
            )
        );
    };

    const onVendorCellClick = (params) => {
        dispatch(showVendorProfileModal(params.data.id));
    };

    const columnList = [
        {
            checkboxSelection: true,
            pinned: 'left',
            headerName: undefined,
            suppressMenu: true,
            width: 55,
            isPublic: true,
        },
        {
            headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
            field: 'legalName',
            pinned: 'left',
            onCellClicked: onVendorCellClick,
            cellRendererFramework: VendorNameCellRenderer,
            width: 310,
            sortable: true,
            isPublic: true,
        },
        {
            headerName: 'Doing Business As (DBA) Name',
            field: 'doingBusinessAs',
            width: 245,
            sortable: true,
            cellRendererFramework: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Subscriber Name',
            field: 'subscriberName',
            cellRendererFramework: ExpandableArrayCellRenderer,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Subscriber Job Title',
            field: 'subscriberJobTitle',
            cellRendererFramework: ExpandableArrayCellRenderer,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Subscriber Email',
            field: 'subscriberEmail',
            cellRendererFramework: ExpandableArrayCellRenderer,
            width: 260,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Subscriber Phone',
            field: 'subscriberPhone',
            cellRendererFramework: ExpandableArrayCellRenderer,
            isPublic: true,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Status',
            field: 'status',
            cellRendererFramework: ExpandableArrayCellRenderer,
            isPublic: false,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Date Subscribed',
            field: 'dateSubscribed',
            cellRendererFramework: ExpandableArrayCellRenderer,
            isPublic: false,
            subscriberViewOnly: true,
        },
        {
            headerName: 'Website',
            field: 'website',
            cellRendererFramework: WebsiteCellRenderer,
            width: 300,
            isPublic: true,
        },
        {
            headerName: 'Company Phone',
            field: 'companyPhone',
            cellRendererFramework: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Address',
            field: 'address',
            cellRendererFramework: ArrayCellRenderer,
            width: 350,
            isPublic: true,
        },
        {
            headerName: 'Languages',
            field: 'languages',
            cellRendererFramework: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Company Description',
            field: 'companyDescription',
            cellRendererFramework: TextCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Verified Certifications',
            field: 'verifiedCertifications',
            cellRendererFramework: ArrayCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Self-Reported Certifications',
            field: 'selfReportedCertifications',
            cellRendererFramework: ArrayCellRenderer,
            width: 225,
            isPublic: true,
        },
        {
            headerName: 'NIGP Category Codes',
            field: 'nigpCategoryCodes',
            cellRendererFramework: CategoriesCellRenderer,
            isPublic: true,
            defaultHidden: true,
        },
        {
            headerName: 'NAICS Category Codes',
            field: 'naicsCategoryCodes',
            cellRendererFramework: CategoriesCellRenderer,
            isPublic: true,
            defaultHidden: true,
        },
        {
            headerName: 'UNSPSC Category Codes',
            field: 'unspscCategoryCodes',
            cellRendererFramework: CategoriesCellRenderer,
            isPublic: true,
            defaultHidden: true,
        },
        {
            headerName: 'Ethnicity',
            field: 'ethnicity',
            cellRendererFramework: ArrayCellRenderer,
            isPublic: true,
        },
        {
            headerName: isPublic ? 'Lists' : 'Public Lists',
            field: 'vendorLists',
            cellRendererFramework: ArrayCellRenderer,
            isPublic: true,
        },
        {
            headerName: 'Private Lists',
            field: 'vendorListsPrivate',
            cellRendererFramework: ArrayCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'Business Type',
            field: 'businessType',
            cellRendererFramework: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'Billing Address',
            field: 'billingAddress',
            cellRendererFramework: ArrayCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'State of Incorporation',
            field: 'stateOfIncorporation',
            cellRendererFramework: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'EIN',
            field: 'ein',
            cellRendererFramework: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'UEI',
            field: 'uei',
            cellRendererFramework: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'DUNS',
            field: 'duns',
            cellRendererFramework: TextCellRenderer,
            isPublic: false,
        },
        {
            headerName: 'OpenGov Supplier ID',
            field: 'openGovSupplierId',
            cellRendererFramework: TextCellRenderer,
            isPublic: false,
        },
    ];

    const [columns, setColumns] = useState(
        columnList.map((column) => {
            if (
                (isPublic && !column.isPublic) ||
                column.subscriberViewOnly ||
                column.defaultHidden
            ) {
                return {
                    ...column,
                    hide: true,
                };
            }
            return column;
        })
    );

    const toggleFilterColumns = (field) => {
        setColumns(
            columns.map((column) => {
                if (column.field === field) {
                    return { ...column, hide: !column.hide };
                }
                return column;
            })
        );
    };

    useEffect(() => {
        setColumns((prevColumns) =>
            prevColumns.map((column) => {
                if (subscriberView && column.subscriberViewOnly && (!isPublic || column.isPublic)) {
                    return { ...column, hide: false };
                }
                if (!subscriberView && column.subscriberViewOnly) return { ...column, hide: true };

                return column;
            })
        );
    }, [subscriberView]);

    return (
        <Box pt={3}>
            <Box display="flex" justifyContent="space-between" pb={2}>
                <Box display="flex" flexDirection="column">
                    <form title="Search vendors by name or address">
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment aria-label="search icon" position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props -- Props are different.
                            inputProps={{
                                title: 'Search vendors by name or address',
                                'aria-label': 'Search vendors by name or address',
                            }}
                            onChange={(e) => {
                                searchParams.set('searchText', e.target.value);
                                setSearchParams(searchParams);
                            }}
                            placeholder="Search names or address"
                            qaTag="vendorSearch-search"
                            sx={{
                                padding: 0,
                                marginBottom: '12px',
                                maxWidth: '421px',
                                width: '100%',
                                '& .MuiInputBase-root': {
                                    margin: 0,
                                },
                            }}
                            value={searchText}
                        />
                        <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    fontWeight: tokens.typography.fontWeightMedium,
                                    fontSize: tokens.typography.fontSizeSmall,
                                }}
                                variant="h3"
                            >
                                Filter By:
                            </Typography>
                            <CategorySelectButton
                                categoryIds={categoryIds}
                                onSelect={onCategorySelect}
                            />
                            <CertificationsMenu
                                handleToggle={onCertificationSelect}
                                selected={certificationIds}
                            />
                            <FiltersMenu
                                isPublic={isPublic}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                            />
                            {hasFilter ? (
                                <Button
                                    onClick={() => setSearchParams(DEFAULT_SEARCH_PARAMS)}
                                    size="small"
                                    startIcon={<CloseIcon />}
                                    sx={{ fontSize: tokens.typography.fontSizeDefault }}
                                >
                                    Clear All
                                </Button>
                            ) : null}
                        </Box>
                    </form>
                </Box>
                <Box display="flex" flexDirection="column" gap={1} justifyContent="flex-end">
                    <Box display="flex" gap={1} justifyContent="flex-end">
                        {!isPublic && (
                            <TakeActionsMenu
                                addVendorsToList={addVendorsToList}
                                addVendorsToListBySearch={addVendorsToListBySearch}
                                blockVendors={blockVendors}
                                emailVendors={emailVendors}
                                emailVendorsBySearch={emailVendorsBySearch}
                                inviteVendorsToProject={inviteVendorsToProject}
                                inviteVendorsToProjectBySearch={inviteVendorsToProjectBySearch}
                                searchParams={getOpenSearchObject({
                                    searchObject,
                                })}
                                selectedUserIds={selectedUserIds}
                            />
                        )}
                        <Columns
                            columns={columns.filter(
                                (column) =>
                                    (subscriberView || !column.subscriberViewOnly) &&
                                    (!isPublic || column.isPublic)
                            )}
                            onClickCheckBox={toggleFilterColumns}
                        />
                        <Button
                            color="secondary"
                            onClick={onExportClick}
                            qaTag="vendorSearch-export"
                            startIcon={<OutputIcon />}
                            variant="text"
                        >
                            Export
                        </Button>
                    </Box>
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        justifyContent="flex-end"
                        pr={1.5}
                    >
                        <Box>
                            <Typography
                                for="switch"
                                id="switchLabel"
                                sx={{
                                    color: tokens.colors.colorGray800,
                                    fontSize: tokens.typography.fontSizeBase,
                                }}
                            >
                                Subscriber Details:
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex" gap={0.5}>
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray1000,
                                    fontWeight: !subscriberView
                                        ? tokens.typography.fontWeightMedium
                                        : tokens.typography.fontWeightRegular,
                                }}
                            >
                                HIDE
                            </Typography>
                            <Switch
                                checked={subscriberView}
                                inputProps={{
                                    id: 'switch',
                                    'aria-label': 'Switch to show or hide subscriber details',
                                    'aria-labelledby': 'switchLabel',
                                }}
                                onChange={(event) => {
                                    setSubscriberView(event.target.checked);
                                }}
                                qaTag="vendorSearch-showHideSubscribers"
                                size="small"
                            />
                            <Typography
                                sx={{
                                    color: tokens.colors.colorGray700,
                                    fontWeight: subscriberView
                                        ? tokens.typography.fontWeightMedium
                                        : tokens.typography.fontWeightRegular,
                                }}
                            >
                                SHOW
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <div className={styles.alignCheckbox}>
                <AgGridReact
                    applyColumnDefOrder
                    cacheBlockSize={CACHE_BLOCK_SIZE}
                    columns={columns.map((column) =>
                        omit(column, ['isPublic', 'subscriberViewOnly', 'defaultHidden'])
                    )}
                    containerStyle={{ height: '634px' }}
                    defaultColDef={defaultColDef}
                    getRowHeight={(params) => {
                        if (params.data.expanded) {
                            const largestArray = Math.max(
                                params.data.subscriberName.length,
                                params.data.subscriberEmail.length,
                                params.data.subscriberPhone.length
                            );
                            return Math.max(largestArray, 1) * 52;
                        }

                        return 52;
                    }}
                    getRowNodeId={getRowNodeId}
                    hideSideBar
                    onGridReady={onGridReady}
                    onSelectionChanged={handleSelectionChanged}
                    pagination
                    paginationPageSize={DEFAULT_PAGE_SIZE}
                    rowModelType="serverSide"
                    subscriberView={subscriberView}
                />
            </div>
        </Box>
    );
};

VendorSearchTable.propTypes = {
    addVendorsToList: PropTypes.func,
    addVendorsToListBySearch: PropTypes.func,
    blockVendors: PropTypes.func,
    emailVendors: PropTypes.func,
    emailVendorsBySearch: PropTypes.func,
    governmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    inviteVendorsToProject: PropTypes.func,
    inviteVendorsToProjectBySearch: PropTypes.func,
    isPublic: PropTypes.bool,
    searchVendors: PropTypes.func.isRequired,
};
