import { redirect } from 'react-router-dom';

import { loadGov } from '../../actions/publicProject';

export default (getState, dispatch) =>
    async ({ params }) => {
        return dispatch(loadGov(params.governmentCode)).then(() => {
            const displayPublicVendorInformation = getState().publicProject.getIn([
                'government',
                'displayPublicVendorInformation',
            ]);

            if (!displayPublicVendorInformation) {
                return redirect('/not-found');
            }

            return null;
        });
    };
