import { requiredValueFieldTypes } from '../../../../shared_config/signatures';
import {
    fieldsEditableFieldNames as signatureFieldsFieldNames,
    fieldNames as signatureFieldNames,
    blockFieldNames as signaturesBlockFieldNames,
} from '../../components/Forms/SignatureForm/constants';
import { REQUIRED_TEXT } from './constants';

export const signatureValidate = (signature, validateValues = false) => {
    const validateBlockArray = (blocks) => {
        return blocks.reduce((blockErrors, block, blockIndex) => {
            const innerErrors = block[signaturesBlockFieldNames.FIELDS].reduce(
                (fieldErrors, field, index) => {
                    const indexErrors = {};
                    if (!field[signatureFieldsFieldNames.LABEL]) {
                        indexErrors[signatureFieldsFieldNames.LABEL] = REQUIRED_TEXT;
                    }

                    // if we want to validate for filled values only do so for required types
                    if (validateValues && requiredValueFieldTypes.includes(field.type)) {
                        if (!field[signatureFieldsFieldNames.VALUE]) {
                            indexErrors[signatureFieldsFieldNames.VALUE] = REQUIRED_TEXT;
                        }
                    }

                    return {
                        ...fieldErrors,
                        [index]: indexErrors,
                    };
                },
                {}
            );

            return {
                ...blockErrors,
                [blockIndex]: {
                    [signaturesBlockFieldNames.FIELDS]: innerErrors,
                },
            };
        }, {});
    };

    if (!signature) {
        return null;
    }

    return {
        [signatureFieldNames.BLOCKS]: signature[signatureFieldNames.BLOCKS].reduce(
            (errors, blocks, index) => {
                const blocksErrors = validateBlockArray(blocks);

                return {
                    ...errors,
                    [index]: blocksErrors,
                };
            },
            {}
        ),
    };
};
