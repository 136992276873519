import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { NoSsr } from '@mui/material';

import { RequisitionsApprovalHistoryNav } from './Nav';
import { RequisitionRelations } from './Relations';
import { RequisitionCommentForm } from './RequisitionCommentForm';
import { RequisitionLogs } from './RequisitionLogs';
import { RequisitionsApprovalHistorySteps } from './Steps';
import { isRequisitionClosed } from '../../../../../../shared_config/requisitions';

export const RequisitionsApprovalHistory = ({ requisition, reviewSequenceSteps }) => {
    const [navIndex, setNavIndex] = useState(0);

    const navItems = [
        <RequisitionsApprovalHistorySteps
            key="steps"
            requisition={requisition}
            reviewSequenceSteps={reviewSequenceSteps}
        />,
        <React.Fragment key="comments">
            {!isRequisitionClosed(requisition.status) && (
                <RequisitionCommentForm requisition={requisition} />
            )}
            <RequisitionLogs requisition={requisition} />
        </React.Fragment>,
        <RequisitionRelations key="related" requisition={requisition} />,
    ];

    return (
        <NoSsr>
            <RequisitionsApprovalHistoryNav setNavIndex={setNavIndex} />
            {navItems[navIndex]}
        </NoSsr>
    );
};

RequisitionsApprovalHistory.propTypes = {
    requisition: PropTypes.shape({
        status: PropTypes.number.isRequired,
    }).isRequired,
    reviewSequenceSteps: PropTypes.array.isRequired,
};
